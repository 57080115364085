// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular.json`.

import { Environment } from '@shared/tokens/environment';

export const environmentTemplate: Omit<Environment, 'pspdfkit'> = {
	name: 'development',
	production: false,
	version: 'craftnote-web@NO_VERSION_SET',
	baseUrl: 'https://europe-west1-craftnote-development.cloudfunctions.net/',
	appUrl: 'https://craftnote-development.firebaseapp.com/#/',
	app_dynamic_link: 'https://craftnotedevelopment.page.link/home',
	firebaseConfig: {
		apiKey: 'AIzaSyAm1iB_FIJ0dRfSCCQBaxJCwFnhWU-SLu8',
		authDomain: 'craftnote-development.firebaseapp.com',
		databaseURL: 'https://craftnote-development.firebaseio.com',
		projectId: 'craftnote-development',
		storageBucket: 'craftnote-development.appspot.com',
		messagingSenderId: '165709396204',
		appId: '1:165709396204:web:b536ce2d40e2b3566ccdc9',
		measurementId: 'G-7P1F0SL4VL',
	},
	stripe: {
		public_key: 'pk_test_w9FTpb7JQTa8zVTApfC7XUGm',
	},
	company_data_bucket: 'craftnote-development-company-data',
	export_data_bucket: 'craftnote-development-exports',
	company_website_url: 'https://profi.craftnote.de',
	planning_board_url: 'https://planning-board-development.web.app',
	external_chat_url: 'https://external-chat-development.web.app',
	public_folders_bucket_name: 'craftnote-development-public-folders',
	zendesk_feedback_button_id: 'a5d1c917-6a97-4af1-9429-6d167e843325',
	appBaseUrl: 'https://craftnote-development.firebaseapp.com',
	publicAssetsBucket: 'craftnote-development-public-assets',
	loopedIn: {
		workspace_id: '8c1b18ba-09b9-4773-97f2-32f072c0aa6b',
	},
	phraseApp: {
		ICEConfig: {
			projectId: '0cf7f94ad9f66924e0d9fb5c421f4d18',
			phraseEnabled: true,
			prefix: '{{__',
			suffix: '__}}',
			fullReparse: true,
			autoLowercase: false,
		},
	},
	globalSearchElasticSearchUrl: 'https://search-api-cxvt3xh3bq-ey.a.run.app',
	restApiUrl: 'https://api-kjzbsdf.bff.web.craftnote.dev',
	realtimeApiUrl: 'https://socket-kjzbsdf.bff.web.craftnote.dev',
	frontendComponents: {
		middleLayer: {
			purchaseWeb: 'https://purchase-confirmation.fs.craftnote.dev',
		},
		scripts: {
			pricingTable:
				'https://web.cdn.craftnote.dev/pricing-table/live/pricing-table/pricing-table.esm.js',
			subscriptionCheckout:
				'https://web.cdn.craftnote.dev/subscription-checkout/live/subscription-checkout/subscription-checkout.esm.js',
			licenseAllocation:
				'https://web.cdn.craftnote.dev/license-allocation/live/license-allocation/license-allocation.esm.js',
			licenseManagement:
				'https://web.cdn.craftnote.dev/license-management/live/license-management/license-management.esm.js',
			opBidList:
				'https://saoppreordermanagement.blob.core.windows.net/oppreordermanagement/opbidlistbc/live/opbidlistbc/opbidlistbc.esm.js',
			opBidDetail:
				'https://saoppreordermanagement.blob.core.windows.net/oppreordermanagement/opbiddetailbc/live/opbiddetailbc/opbiddetailbc.esm.js',
			opInvoiceList:
				'https://saoppreordermanagement.blob.core.windows.net/oppreordermanagement/opinvoicelistbc/live/opinvoicelistbc/opinvoicelistbc.esm.js',
			opInvoiceDetail:
				'https://saoppreordermanagement.blob.core.windows.net/oppreordermanagement/opinvoicedetailbc/live/opinvoicedetailbc/opinvoicedetailbc.esm.js'
		},
	},
};
