import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, pluck, switchMap } from 'rxjs/operators';
import { Task, TaskRepeatType } from 'domain-entities';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import moment, { DurationInputArg1, DurationInputArg2 } from 'moment';
import { isNil } from 'lodash';
import { BasicSnackbarComponent } from '@modules/shared/components/notification-snackbar/basic-snackbar/basic-snackbar.component';
import { TranslateService } from '@ngx-translate/core';
import { TasksService } from '@injectables/services/tasks/tasks.service';
import { shareReplayOne } from '@craftnote/shared-utils';
import { NotificationSnackbarService } from '@injectables/services/notification-snackbar/notification-snackbar.service';
import { take } from 'rxjs/operators';

const timeOffsetMap: { [keys: string]: { amount: DurationInputArg1; unit: DurationInputArg2 } } = {
	[TaskRepeatType.DAILY]: { amount: 1, unit: 'days' },
	[TaskRepeatType.WEEKLY]: { amount: 1, unit: 'weeks' },
	[TaskRepeatType.BIWEEKLY]: { amount: 2, unit: 'weeks' },
	[TaskRepeatType.MONTHLY]: { amount: 1, unit: 'months' },
	[TaskRepeatType.YEARLY]: { amount: 1, unit: 'years' },
};

enum TaskListType {
	OVER_DUE = 'overDue',
	DUE_TODAY = 'dueToday',
	UP_COMING = 'upComing',
}

interface TaskList {
	list: Task[];
	title: string;
	type: TaskListType;
}

export enum TaskFilterBy {
	OPEN = 'open',
	COMPLETED = 'completed',
}

enum TaskSortBy {
	TITLE_A_Z = 'titleAZ',
	TITLE_Z_A = 'titleZA',
	ASSIGNEE_A_Z = 'assigneeAZ',
	ASSIGNEE_Z_A = 'assigneeZA',
	DUE_DATE_L_O = 'dueDateLO',
	DUE_DATE_O_L = 'dueDateOL',
}

@Component({
	selector: 'app-project-tasks-list',
	templateUrl: './project-tasks-list.component.html',
	styleUrls: ['./project-tasks-list.component.scss'],
})
export class ProjectTasksListComponent implements OnInit {
	TaskFilterBy = TaskFilterBy;
	TaskSortBy = TaskSortBy;
	private readonly STORAGE_KEY_PREFIX = "taskSort_";

	projectId$ = this.activatedRoute.params.pipe(pluck('projectId'), shareReplayOne());

	tasks$: Observable<Task[]> = this.projectId$.pipe(
		switchMap((projectId) => this.tasksService.getTasksByProjectId(projectId)),
		shareReplayOne(),
	);

	taskSortBy$$: BehaviorSubject<TaskSortBy> = new BehaviorSubject(TaskSortBy.DUE_DATE_L_O);
	taskFilterBy$$: BehaviorSubject<TaskFilterBy> = new BehaviorSubject(
		this.activatedRoute.snapshot.queryParamMap.get('filter') === TaskFilterBy.COMPLETED
			? TaskFilterBy.COMPLETED
			: TaskFilterBy.OPEN,
	);

	tasksListUI$: Observable<TaskList[]> = combineLatest([
		this.taskSortBy$$,
		this.taskFilterBy$$,
		this.tasks$,
	]).pipe(
		map(([sortBy, filterBy, tasks]) =>
			this.sortTasks(this.segregateTasks(this.filterTasks(tasks, filterBy)), sortBy),
		),
	);

	completedTasksLength: Observable<number> = this.tasks$.pipe(
		map((tasks) => tasks.filter((task) => task.done)),
		pluck('length'),
		shareReplayOne(),
	);

	openTasksLength: Observable<number> = this.tasks$.pipe(
		map((tasks) => tasks.filter((task) => !task.done)),
		pluck('length'),
		shareReplayOne(),
	);

	constructor(
		public readonly activatedRoute: ActivatedRoute,
		private readonly tasksService: TasksService,
		private readonly notificationSnackbarService: NotificationSnackbarService,
		private readonly translate: TranslateService,
		private readonly router: Router,
	) {}

	ngOnInit(): void {
		this.projectId$.pipe(take(1)).subscribe(projectId => {
			const storedSort = localStorage.getItem(this.STORAGE_KEY_PREFIX + projectId);
			if (storedSort && Object.values(TaskSortBy).includes(storedSort as TaskSortBy)) {
				this.taskSortBy$$.next(storedSort as TaskSortBy);
			}
		});

		combineLatest([this.projectId$, this.taskSortBy$$]).subscribe(([projectId, sortBy]) => {
			localStorage.setItem(this.STORAGE_KEY_PREFIX + projectId, sortBy);
		});
	}

	filterTasks(tasks: Task[], filterBy: TaskFilterBy): Task[] {
		return tasks.filter((task) => (filterBy === TaskFilterBy.COMPLETED ? !!task.done : !task.done));
	}

	sortTasks(tasksList: TaskList[], sortBy: TaskSortBy): TaskList[] {
		tasksList.forEach((taskList) => {
			if (sortBy === TaskSortBy.ASSIGNEE_A_Z) {
				taskList.list.sort((a, b) => {
					// Tasks without assignee should be on the top of list
					if (isNil(b.assignee)) {
						return 1;
					}
					if (isNil(a.assignee)) {
						return -1;
					}
					return a.assignee?.toLowerCase() > b.assignee?.toLowerCase() ? 1 : -1;
				});
			}
			if (sortBy === TaskSortBy.ASSIGNEE_Z_A) {
				taskList.list.sort((a, b) => {
					// Tasks without assignee should be on the top of list
					if (isNil(b.assignee)) {
						return 1;
					}
					if (isNil(a.assignee)) {
						return -1;
					}
					return a.assignee?.toLowerCase() < b.assignee?.toLowerCase() ? 1 : -1;
				});
			}
			if (sortBy === TaskSortBy.TITLE_A_Z) {
				taskList.list.sort((a, b) => (a.title?.toLowerCase() > b.title?.toLowerCase() ? 1 : -1));
			}
			if (sortBy === TaskSortBy.TITLE_Z_A) {
				taskList.list.sort((a, b) => (a.title?.toLowerCase() < b.title?.toLowerCase() ? 1 : -1));
			}
			if (sortBy === TaskSortBy.DUE_DATE_L_O) {
				taskList.list.sort((a, b) => {
					// Tasks without deadlineTimestamp should be on the top of list
					if (isNil(b.deadlineTimestamp)) {
						return 1;
					}
					if (isNil(a.deadlineTimestamp)) {
						return -1;
					}
					return a.deadlineTimestamp < b.deadlineTimestamp ? 1 : -1;
				});
			}
			if (sortBy === TaskSortBy.DUE_DATE_O_L) {
				taskList.list.sort((a, b) => {
					if (isNil(b.deadlineTimestamp)) {
						return 1;
					}
					if (isNil(a.deadlineTimestamp)) {
						return -1;
					}
					return a.deadlineTimestamp > b.deadlineTimestamp ? 1 : -1;
				});
			}
		});
		return tasksList;
	}

	segregateTasks(tasks: Task[]): TaskList[] {
		return tasks.reduce(
			(tasksListUI: TaskList[], task: Task) => {
				const [overDueList, dueTodayList, upComingList] = tasksListUI;

				// If task does not have deadlineTimestamp then move it to the upcoming category
				if (isNil(task.deadlineTimestamp)) {
					upComingList.list.push(task);
					return tasksListUI;
				}

				// overDue
				if (moment().diff(moment.unix(task.deadlineTimestamp), 'seconds') > 0) {
					overDueList.list.push(task);
					return tasksListUI;
				}
				// dueToday
				if (
					moment
						.unix(task.deadlineTimestamp)
						.isBetween(moment(), moment().endOf('day'), undefined, '[]')
				) {
					dueTodayList.list.push(task);
					return tasksListUI;
				}
				// upComing
				upComingList.list.push(task);
				return tasksListUI;
			},
			[
				{
					list: [],
					title: this.translate.instant('project-task-list.overdue'),
					type: TaskListType.OVER_DUE,
				},
				{
					list: [],
					title: this.translate.instant('project-task-list.due-today'),
					type: TaskListType.DUE_TODAY,
				},
				{
					list: [],
					title: this.translate.instant('project-task-list.upcoming'),
					type: TaskListType.UP_COMING,
				},
			],
		);
	}

	calculateNewDeadlineTimestamp(repeatType: TaskRepeatType, lastDeadlineTimestamp: Date): Date {
		const { amount = 0, unit = 'days' } = timeOffsetMap[repeatType];
		return moment(lastDeadlineTimestamp).add(amount, unit).toDate();
	}

	async toggleTaskStatus(task: Task): Promise<void> {
		const updatedTask = { ...task, done: !task.done };

		if (updatedTask.done) {
			this.notificationSnackbarService.show(BasicSnackbarComponent, {
				componentTypes: {
					description: this.translate.instant('task.task-completion-notification'),
					icon: 'done',
				},
				level: 1,
			});

			if (
				updatedTask.repeatType &&
				updatedTask.repeatType !== TaskRepeatType.NEVER &&
				updatedTask.deadlineTimestamp
			) {
				updatedTask.deadlineTimestamp = moment(
					this.calculateNewDeadlineTimestamp(
						updatedTask.repeatType,
						moment.unix(updatedTask.deadlineTimestamp).toDate(),
					),
				).unix();
				//update deadline timestamp values first and set to done
				await this.tasksService.updateTask(updatedTask);

				//set done to false again this is needed for backend to send a chat message that the task has been completed
				await this.tasksService.updateTask({
					id: updatedTask.id,
					done: false,
				});

				return;
			}
		}
		await this.tasksService.updateTask(updatedTask);
	}

	async openTaskEdit(task: Task): Promise<void> {
		await this.router.navigate([task.id, 'edit'], {
			queryParams: {
				filter: task.done ? TaskFilterBy.COMPLETED : TaskFilterBy.OPEN,
			},
			relativeTo: this.activatedRoute,
		});
	}
}
